.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* MyStyledForm.css */

body {
  background-color: #f0f0f0;
  color: #333;
}
.form-container {
  background-color: #234465;
  color: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 50px;
}
.form-container select, .form-container input, .form-container textarea {
  margin-bottom: 15px;
}
.form-container button {
  background-color: #14568f;
  color: white;
  border: none;
  transition: background-color 0.3s ease-in-out;
}
.form-container button:hover {
  background-color: #0c3a6b;
}


/* UPDATED STYLING FOR AUTO SUGGEST COMPONENTS */

.autosuggest-container {
  position: relative;
}

.autosuggest-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.autosuggest-suggestions {
  display: none;
  position: absolute;
  padding-left: 0; /* Add this line to remove default padding */
  list-style: none; /* Remove the bullet points */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: calc(100% - 2px);
}

.autosuggest-suggestions.show {
  display: block;
}

.autosuggest-suggestion {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Added color transition */
}

.autosuggest-suggestion:hover {
  background-color: #f5f5f5;
  color: #333333; /* Change text color when suggestion is active */
  font-weight: bold;
}

.autosuggest-suggestion.active {
  background-color: #007bff; /* Example: Change background color when suggestion is active */
  color: #333333; /* Change text color when suggestion is active */
  font-weight: bold;
}


button[type="submit"]:hover {
  font-weight: bold;
}

/* Refund.css */

.refund-label {
  margin-right: 10px;
}

.refund-radio-group {
  display: flex;
  gap: 10px;
}

.refund-radio-label {
  margin-right: 20px;
}

.refund-input-label {
  margin-bottom: 5px;
}

.refund-input {
  margin-bottom: 15px;
}


.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.centered-image {
  max-width: 100%; /* Ensure the image doesn't overflow */
  max-height: 100%; /* Ensure the image doesn't overflow */
}